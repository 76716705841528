<template>
  <div class="w-full flex flex-col justify-center items-center">
    <div v-if="loading" class="flex justify-center items-center">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <Wrapper v-if="complaint" class="max-w-2xl mb-4">
      <h2 class="mb-8 text-blue-dark text-left text-3xl">
        <b>Detalle de queja</b>
      </h2>
      <ComplaintDetailHeaderCard
        :radicado="$route.params.complaint_id"
        detail="true"
        :status="complaint.estado_cod"
      />

      <ComplaintDetailForm v-if="complaint" :complaint="complaint" />
      <div
        class="flex flex-col gap-2"
        v-if="complaint.archivos_apoderado.length !== 0"
      >
        <label
          class="text-xs md:text-xs text-gray-600 text-opacity-80 mt-5 text-left"
          ><b>Anexar autorización del poder</b></label
        >
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in complaint.archivos_apoderado"
            :value="option.name_file"
            :key="index"
            :url_download="option.url_file"
          />
        </div>
      </div>
      <div class="w-full my-5">
        <a-button
          type="danger"
          shape="round"
          :block="true"
          ghost
          @click.native="$router.go(-1)"
        >
          VOLVER
        </a-button>
      </div>
      <div class="w-full my-5">
        <BaseButton
          v-if="complaint.estado_cod !== 'Cerrada' && annexesSize < 90"
          @click.native="goAttachments"
          label="ADJUNTAR NUEVOS ANEXOS"
          class="w-full"
          :append="true"
          :disabled="complaint.desistimiento_queja == 1"
        />
      </div>

      <div
        v-if="
          complaint.estado_cod != 'Cerrada' &&
          (complaint.desistimiento_queja == 2 ||
            complaint.desistimiento_queja == null)
        "
        id="desistComplain"
        class="w-full my-5"
      >
        <a-button
          type="danger"
          shape="round"
          @click.native="openModal()"
          :block="true"
          ghost
        >
          DESISTIR DE QUEJA
        </a-button>
      </div>
      <hr />
      <div class="w-full my-8">
        <p
          v-if="complaint.desistimiento_queja == 1"
          class="text-left rounded-xl"
        >
          Ha desistido de la interposición de su queja.
        </p>
        <p
          v-if="
            (complaint.desistimiento_queja == 2 ||
              complaint.desistimiento_queja == null) &&
            complaint.estado_cod == 'Recibida' &&
            !complaint.vencido_por_tiempo
          "
          class="text-left rounded-xl text-gray-600"
        >
        La queja está siendo revisada por la entidad vigilada y le enviará una
        respuesta de manera directa a usted.
        </p>
        <p
          v-if="complaint.vencido_por_tiempo"
          class="text-left rounded-xl text-gray-600"
        >
          Han trascurrido dos meses después de recibida la respuesta por parte
          de la entidad. En caso de que persista su inconformidad puede radicar
          una queja nueva.
        </p>
      </div>
      <div
        v-if="
          (complaint.desistimiento_queja == 2 ||
            complaint.desistimiento_queja == null) &&
          !complaint.vencido_por_tiempo
        "
      >
        <div
          v-if="complaint.estado_cod == 'Cerrada'"
          class="w-full flex flex-col gap-4"
        >
          <BaseRouterLink
            v-if="
              complaint.tipo_gestion_actual == 0 &&
              complaint.marcacion_id !== marcation_id
            "
            to="escalate"
            label="QUIERO TRAMITAR MI QUEJA ANTE EL DEFENSOR DEL CONSUMIDOR FINANCIERO"
            class="w-full"
            invert="true"
            :append="true"
          />

          <a-button
            v-if="
              complaint.tipo_gestion_actual == 0 &&
              complaint.marcacion_id !== marcation_id
            "
            type="primary"
            shape="round"
            @click.native="$router.push({ path: 'replica', append: true })"
          >
            QUIERO PRESENTAR UNA RÉPLICA
          </a-button>
        </div>

        <!-- <div v-if=" !complaint.calificado" class="w-full mt-5 flex">
          <BaseRouterLink :to="`${'/customer/complaint/calification/'+$route.params.complaint_id}`" id="clasifyService" label="CUÉNTENOS SU EXPERIENCIA CON LA PLATAFORMA"
            class="w-full" success="true" :append="true" />
        </div> -->
      </div>
    </Wrapper>
    <AnnexesManagement
      v-if="complaint"
      class="max-w-2xl"
      :complaint="complaint"
      :references="references"
    />
    <AnswerManagement
      v-if="complaint"
      class="max-w-2xl"
      :complaint="complaint"
    />
    <div v-if="complaint" class="w-11/12 max-w-2xl">
      <AccordionList :items="accordionList" />
    </div>
    <!-- MODAL -->
    <Modal v-if="showModal">
      <div class="w-11/12 mx-auto">
        <h2 class="mb-3 text-2xl text-blue-dark">
          <b>Desisto de la queja presentada</b>
        </h2>
      </div>
      <div class="w-11/12 mx-auto my-5">
        <p class="text-xs text-grey-400"><b>Importante</b></p>
        <p class="text-sm">Señor consumidor financiero:</p>
        <p class="text-sm">
          Al confirmar esta acción se dará por finalizado y cerrado el trámite
          de la inconformidad presentada.
        </p>
      </div>
      <div
        class="w-3/5 mx-auto flex flex-col items-center justify-center md:justify-center md:items-center gap-5"
      >
        <a-button
          type="danger"
          :block="true"
          shape="round"
          ghost
          @click.native="closeModal"
          :loading="loadingDesist"
        >
          CANCELAR
        </a-button>
        <a-button
          type="primary"
          :block="true"
          shape="round"
          @click.native="desistComplaint"
          :loading="loadingDesist"
        >
          DESISTIR DE LA QUEJA
        </a-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import ComplaintDetailHeaderCard from "@/components/Customer/Complaint/ComplaintDetailHeaderCard";
import ComplaintDetailForm from "@/components/Customer/Complaint/ComplaintDetailForm";
import AnswerManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnswerManagement";
import AnnexesManagement from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesManagement";
import { format } from "date-fns";
export default {
  components: {
    AnswerManagement,
    ComplaintDetailHeaderCard,
    ComplaintDetailForm,
    AnnexesManagement,
  },
  async created() {
    this.loading = true;
    const { data, error } = await this.$api.getComplaintById(
      this.$route.params.complaint_id
    );
    this.loading = false;
    if (error) {
      this.showToast("error", "Error recuperando información de la queja");
      return;
    }

    if (data) {
      const complaint = data;
      complaint.mis_archivos = complaint.mis_archivos
        ? complaint.mis_archivos
        : [];
      this.complaint = {
        ...complaint,
        lgbtiq: complaint.lgbtiq == 1 ? "Sí" : complaint.lgbtiq == 2 ? "No": "",
        specialCondition:
            complaint.condicion_especial === "No aplica"?
              2: complaint.condicion_especial == null ? 
              null:
              1,
        es_apoderado: complaint.apoderado_nombre ? 2 : 1,
        abroad: complaint.departamento_cod && complaint.municipio_cod ? 2 : 1,
        documentacion_final: complaint.documentacion_rta_final ? "Sí" : "No",
        expres: complaint.queja_expres === 1 ? "Sí" : "No",
        tutela_nombre:
          complaint.tutela === 1
            ? "La queja o reclamo tiene asociada una acción de tutela"
            : "La queja o reclamo tiene NO asociada una acción de tutela",
        foreign: complaint.departamento_cod === null ? true : false,
        archivos_apoderado: complaint?.mis_archivos?.filter(
          (item) => item.reference === "attorney"
        ),

        fecha_cierre: complaint.fecha_cierre
          ? format(new Date(complaint.fecha_cierre), "dd/MM/yyyy hh:mm a")
          : "",
        tipo_gestion_actual: (complaint.historico_gestion
          ? complaint.historico_gestion
          : [{ cod_tipo_gestion: complaint.tipo_gestion_actual }]
        ).filter(
          (item) =>
            item.cod_tipo_gestion !== 6 &&
            item.cod_tipo_gestion !== 7 &&
            item.cod_tipo_gestion !== 8
        ).length,
      };
    }
  },
  data() {
    return {
      marcation_id: 7,
      references:"!,2,6,7,8",  // !:not in. 2:attorney. 6:management. 7:answer 8:fraud
      complaint: "",
      accordionList: [
        {
          title:
            "¿Cuál es el plazo que tiene la entidad vigilada para responder?",
          body: "La entidad cuenta con 15 días hábiles a la recepción de la queja, para dar respuesta. En caso en que la entidad requiera de un mayor plazo para atender la queja podrá informar al consumidor para obtener un tiempo adicional para responder.",
        },
        {
          title: "¿Qué pasa si se desiste de la queja?",
          body: "La consecuencia del desistimiento es que la queja será cerrada y recibirá un correo electrónico en el que se informará la modificación del estado de la misma.  Si Usted desiste no podrá continuar con el trámite. Si persisten las inconsistencias después de pasado un tiempo deberá radicar una nueva queja.",
        },
        {
          title: "¿Cuándo puedo hacer una réplica?",
          body: "En caso de que usted esté inconforme con la respuesta dada por la entidad vigilada, puede presentar una replicar dentro de los dos meses siguientes.",
        },
        {
          title: "¿Cuándo no puedo presentar una réplica?",
          body: "Si trascurridos los dos meses después de recibida la respuesta el consumidor no hace uso de la réplica, la SFC dará por finalizado el trámite. En caso de que persista la inconformidad el consumidor puede radicar una queja nueva.",
        },
        {
          title:
            "¿Al presentar una réplica debo adjuntar nuevamente todos los documentos  de la queja?",
          body: "No, pero debe describir los argumentos por los cuales no está conforme con la respuesta de la entidad vigilada y anexar los soportes adicionales que considere necesarios.",
        },
        {
          title: "¿Cuántas veces puedo presentar una réplica?",
          body: "Usted puede presentar el número de réplicas que considere necesario, siempre y cuando lo haga dentro del tiempo establecido.",
        },
        {
          title: "¿Quién es el Defensor del Consumidor Financiero?",
          body: "Es quien ejerce la vocería de los consumidores financieros en cada una de las entidades vigiladas para conocer y resolver las inconformidades presentadas por los productos y servicios financieros que estos ofrecen.",
        },
      ],
      form: {
        name: "",
        lastName: "",
        wichCondition: "",
        identificationType: "",
        identificationNumber: "",
        abroad: "",
        product: "",
        country: "",
        channel: "",
      },
      loading: false,
      showModal: false,
      feedback: "",
      abroad: 0,
      specialCondition: 0,
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsApoderado: [
        { id: 1, name: "Actúo en nombre propio" },
        { id: 2, name: "Soy apoderado" },
      ],
      loadingDesist: false,
    };
  },
  methods: {
    async desistComplaint() {
      let form = {
        is_sync: false,
        desistimiento_queja: 1,
        read_datetime: null,
      };
      this.loadingDesist = true;
      let { data, error } = await this.$api.desistComplaint(
        this.complaint.codigo_queja,
        form
      );
      if (error) {
        this.showModal = false;
        this.$notification.error({
          message: "Error",
          description: "Ocurrio un error al desistir la queja",
        });
      }
      this.loadingDesist = false;
      if (data) {
        this.showModal = false;
        this.$store.dispatch("complaints/loadComplaints");
        this.$router.push({
          name: "customerComplaintMessage",
          query: {
            complaintCode: data.codigo_queja,
            type: "close",
            rated: data.calificado,
          },
        });
      }
    },
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    goAttachments() {
      this.$router.push(
        `/customer/complaint/details/${this.complaint.codigo_queja}/anexos/${this.complaint.estado_cod}/${this.annexesSize}`
      );
    },
  },
  computed: {
    annexesSize: function () {
      let fileSize = 0;
      this.complaint?.mis_archivos?.forEach((file) => {
        fileSize += file.size_file;
      });
      return fileSize;
    },
  },
};
</script>

<style></style>
