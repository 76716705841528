var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col justify-center items-center"},[(_vm.loading)?_c('div',{staticClass:"flex justify-center items-center"},[_c('a-spin',[_c('a-icon',{staticStyle:{"font-size":"64px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1)],1):_vm._e(),(_vm.complaint)?_c('Wrapper',{staticClass:"max-w-2xl mb-4"},[_c('h2',{staticClass:"mb-8 text-blue-dark text-left text-3xl"},[_c('b',[_vm._v("Detalle de queja")])]),_c('ComplaintDetailHeaderCard',{attrs:{"radicado":_vm.$route.params.complaint_id,"detail":"true","status":_vm.complaint.estado_cod}}),(_vm.complaint)?_c('ComplaintDetailForm',{attrs:{"complaint":_vm.complaint}}):_vm._e(),(_vm.complaint.archivos_apoderado.length !== 0)?_c('div',{staticClass:"flex flex-col gap-2"},[_c('label',{staticClass:"text-xs md:text-xs text-gray-600 text-opacity-80 mt-5 text-left"},[_c('b',[_vm._v("Anexar autorización del poder")])]),_c('div',{staticClass:"w-11/12 grid grid-cols-1 gap-2"},_vm._l((_vm.complaint.archivos_apoderado),function(option,index){return _c('BaseDownload',{key:index,attrs:{"value":option.name_file,"url_download":option.url_file}})}),1)]):_vm._e(),_c('div',{staticClass:"w-full my-5"},[_c('a-button',{attrs:{"type":"danger","shape":"round","block":true,"ghost":""},nativeOn:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" VOLVER ")])],1),_c('div',{staticClass:"w-full my-5"},[(_vm.complaint.estado_cod !== 'Cerrada' && _vm.annexesSize < 90)?_c('BaseButton',{staticClass:"w-full",attrs:{"label":"ADJUNTAR NUEVOS ANEXOS","append":true,"disabled":_vm.complaint.desistimiento_queja == 1},nativeOn:{"click":function($event){return _vm.goAttachments.apply(null, arguments)}}}):_vm._e()],1),(
        _vm.complaint.estado_cod != 'Cerrada' &&
        (_vm.complaint.desistimiento_queja == 2 ||
          _vm.complaint.desistimiento_queja == null)
      )?_c('div',{staticClass:"w-full my-5",attrs:{"id":"desistComplain"}},[_c('a-button',{attrs:{"type":"danger","shape":"round","block":true,"ghost":""},nativeOn:{"click":function($event){return _vm.openModal()}}},[_vm._v(" DESISTIR DE QUEJA ")])],1):_vm._e(),_c('hr'),_c('div',{staticClass:"w-full my-8"},[(_vm.complaint.desistimiento_queja == 1)?_c('p',{staticClass:"text-left rounded-xl"},[_vm._v(" Ha desistido de la interposición de su queja. ")]):_vm._e(),(
          (_vm.complaint.desistimiento_queja == 2 ||
            _vm.complaint.desistimiento_queja == null) &&
          _vm.complaint.estado_cod == 'Recibida' &&
          !_vm.complaint.vencido_por_tiempo
        )?_c('p',{staticClass:"text-left rounded-xl text-gray-600"},[_vm._v(" La queja está siendo revisada por la entidad vigilada y le enviará una respuesta de manera directa a usted. ")]):_vm._e(),(_vm.complaint.vencido_por_tiempo)?_c('p',{staticClass:"text-left rounded-xl text-gray-600"},[_vm._v(" Han trascurrido dos meses después de recibida la respuesta por parte de la entidad. En caso de que persista su inconformidad puede radicar una queja nueva. ")]):_vm._e()]),(
        (_vm.complaint.desistimiento_queja == 2 ||
          _vm.complaint.desistimiento_queja == null) &&
        !_vm.complaint.vencido_por_tiempo
      )?_c('div',[(_vm.complaint.estado_cod == 'Cerrada')?_c('div',{staticClass:"w-full flex flex-col gap-4"},[(
            _vm.complaint.tipo_gestion_actual == 0 &&
            _vm.complaint.marcacion_id !== _vm.marcation_id
          )?_c('BaseRouterLink',{staticClass:"w-full",attrs:{"to":"escalate","label":"QUIERO TRAMITAR MI QUEJA ANTE EL DEFENSOR DEL CONSUMIDOR FINANCIERO","invert":"true","append":true}}):_vm._e(),(
            _vm.complaint.tipo_gestion_actual == 0 &&
            _vm.complaint.marcacion_id !== _vm.marcation_id
          )?_c('a-button',{attrs:{"type":"primary","shape":"round"},nativeOn:{"click":function($event){return _vm.$router.push({ path: 'replica', append: true })}}},[_vm._v(" QUIERO PRESENTAR UNA RÉPLICA ")]):_vm._e()],1):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.complaint)?_c('AnnexesManagement',{staticClass:"max-w-2xl",attrs:{"complaint":_vm.complaint,"references":_vm.references}}):_vm._e(),(_vm.complaint)?_c('AnswerManagement',{staticClass:"max-w-2xl",attrs:{"complaint":_vm.complaint}}):_vm._e(),(_vm.complaint)?_c('div',{staticClass:"w-11/12 max-w-2xl"},[_c('AccordionList',{attrs:{"items":_vm.accordionList}})],1):_vm._e(),(_vm.showModal)?_c('Modal',[_c('div',{staticClass:"w-11/12 mx-auto"},[_c('h2',{staticClass:"mb-3 text-2xl text-blue-dark"},[_c('b',[_vm._v("Desisto de la queja presentada")])])]),_c('div',{staticClass:"w-11/12 mx-auto my-5"},[_c('p',{staticClass:"text-xs text-grey-400"},[_c('b',[_vm._v("Importante")])]),_c('p',{staticClass:"text-sm"},[_vm._v("Señor consumidor financiero:")]),_c('p',{staticClass:"text-sm"},[_vm._v(" Al confirmar esta acción se dará por finalizado y cerrado el trámite de la inconformidad presentada. ")])]),_c('div',{staticClass:"w-3/5 mx-auto flex flex-col items-center justify-center md:justify-center md:items-center gap-5"},[_c('a-button',{attrs:{"type":"danger","block":true,"shape":"round","ghost":"","loading":_vm.loadingDesist},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v(" CANCELAR ")]),_c('a-button',{attrs:{"type":"primary","block":true,"shape":"round","loading":_vm.loadingDesist},nativeOn:{"click":function($event){return _vm.desistComplaint.apply(null, arguments)}}},[_vm._v(" DESISTIR DE LA QUEJA ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }